import React, { useState, useCallback, useEffect } from "react";
import cx from "classnames";
import { useEmblaCarousel } from "embla-carousel/react";
import { PrevButton, NextButton } from "src/components/embla-carousel-buttons";
import { getDateTimeParts } from "src/utils/getDateTimeParts";

import styles from "./month-nav.module.css";

export interface MonthNavProps {
	eventsByMonth: any;
	month: string;
	selectMonth: (date: string) => void;
}

export const MonthNav = ({
	eventsByMonth,
	month,
	selectMonth,
}: MonthNavProps) => {
	const [activeYear, setActiveYear] = useState(new Date().getFullYear());

	const [viewportRef, embla] = useEmblaCarousel({
		align: "start",
	});
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

	const onSelect = useCallback(() => {
		if (!embla) return;

		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());

		const activeIndex = embla.selectedScrollSnap();
		const activeSlideNode = [...embla.slideNodes()][activeIndex];
		selectMonth(activeSlideNode.dataset.yearMonth);
		setActiveYear(activeSlideNode.dataset.year);
	}, [embla]);

	useEffect(() => {
		if (!embla) return;
		embla.on("select", onSelect);
		onSelect();
	}, [eventsByMonth, embla, onSelect]);

	return (
		<div className={styles.monthNav}>
			<div className="embla x">
				<div className="embla__viewport x" ref={viewportRef}>
					<div className={cx("embla__container x", styles.carousel)}>
						{eventsByMonth &&
							Object.entries(eventsByMonth).map(([key, value]) => {
								const { month, year } = getDateTimeParts(value[0].start.local);
								return (
									<div
										key={key}
										id={key}
										data-year-month={key}
										data-year={year}
										data-month={month}
										className={styles.slide}
									>
										<div className={cx("sans lower medium", styles.month)}>
											<span>{month}</span>
										</div>
									</div>
								);
							})}
					</div>
				</div>
			</div>
			<div className={styles.carouselControls}>
				<PrevButton
					className={styles.carouselButton}
					onClick={scrollPrev}
					enabled={prevBtnEnabled}
				/>
				<span className={cx("sans sans--caps-11 medium")}>{activeYear}</span>
				<NextButton
					className={styles.carouselButton}
					onClick={scrollNext}
					enabled={nextBtnEnabled}
				/>
			</div>
		</div>
	);
};
