import React, { useState, useEffect } from "react";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { groupBy } from "lodash";
import { useSetPage } from "src/context/siteContext";
import { eventbriteConfig } from "src/utils/eventbriteConfig";
import useRequest from "src/utils/useRequest";

import { SEO } from "src/components/SEO";
import { MonthNav } from "./month-nav";
import { Month } from "./month";
import { LoadingMessage } from "src/components/global/loading-message";

import styles from "./events.module.css";

export interface EventsProps {
	pageContext: any;
}

export const Events = ({ pageContext }: EventsProps) => {
	// fetch eventbrite events data
	const { data, error, isValidating } = useRequest({
		method: "get",
		headers: {
			Authorization: `Bearer ${eventbriteConfig.privateToken}`,
		},
		baseURL: eventbriteConfig.baseURL,
		url: `/organizations/${eventbriteConfig.organizationId}/events/?order_by=start_asc&status=live&page_size=50&time_filter=all&expand=venue, listing_properties`,
	});

	// setup events data in state
	const [eventsByMonth, setEventsByMonth] = useState(null);
	const [month, setMonth] = useState(null);

	const setPage = useSetPage();

	useEffect(() => {
		setPage("events");
	}, []);

	useEffect(() => {
		if (!data) {
			return;
		}

		const eventsByMonthData = groupBy(data.events, (event) => {
			return event.start.local.substring(0, 7);
		});

		// group events by month and set in state
		setEventsByMonth(eventsByMonthData);

		// set current month to the month of first event
		setMonth(Object.keys(eventsByMonthData)[0]);
	}, [data]);

	useEffect(() => {
		if (!eventsByMonth) {
			return;
		}

		// set current month to the month of first event
		setMonth(Object.keys(eventsByMonth)[0]);
	}, [eventsByMonth]);

	useEffect(() => {
		// scroll to top on month update
		window.scroll(0, 0);
	}, [month]);

	const selectMonth = (date: string) => {
		setMonth(date);
	};

	if (error) {
		console.log(error);
	}

	return (
		<>
			{/* <SEO pagePath="/events" /> */}
			<div className={cx("events", styles.events)}>
				{error && <div>Error!</div>}

				{!error && isValidating && <LoadingMessage message="Loading" />}
				<div className={styles.monthNavColumn}>
					{eventsByMonth && (
						<MonthNav
							eventsByMonth={eventsByMonth}
							month={month}
							selectMonth={selectMonth}
						/>
					)}
				</div>
				<div className={styles.monthColumn}>
					{eventsByMonth &&
						Object.entries(eventsByMonth).map(([key, events]) => {
							return (
								<AnimatePresence exitBeforeEnter={false} key={key}>
									{key === month && (
										<motion.div
											className={styles.monthContainer}
											initial={{ opacity: 0 }}
											animate={{
												opacity: 1,
												transition: { duration: 1 },
											}}
											exit={{
												opacity: 0,
												transition: { duration: 0 },
											}}
										>
											<Month events={events} />
										</motion.div>
									)}
								</AnimatePresence>
							);
						})}
				</div>
			</div>
		</>
	);
};
