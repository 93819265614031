import React, { useEffect, useState } from "react";
import cx from "classnames";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Element as ScrollElement } from "react-scroll";
import { eventbriteConfig } from "src/utils/eventbriteConfig";
import { getDateTimeParts } from "src/utils/getDateTimeParts";

import styles from "./event.module.css";

export interface EventProps {
	event: any;
}

export const Event = ({ event }: EventProps) => {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);

	const [ref, inView] = useInView({
		threshold: 0,
		triggerOnce: true,
	});

	useEffect(() => {
		if (inView) {
			axios
				.get(
					`${eventbriteConfig.baseURL}/events/${event.id}/structured_content/?purpose=listing`,
					{
						method: "get",
						headers: {
							Authorization: `Bearer ${eventbriteConfig.privateToken}`,
						},
					}
				)
				.then((response) => {
					const data = response.data;
					setData(data);
				})
				.catch((error) => {
					console.log(error);
					setError(error);
				});
		}
	}, [inView]);

	const eventStructuredContent = !error && data && data.modules;

	const { month, day, weekday, year } = getDateTimeParts(event.start.local);

	return (
		<ScrollElement name={event.id} className={styles.eventContainer}>
			<div className={styles.event} ref={ref}>
				<div className={styles.eventContent}>
					<div className={styles.eventDate}>
						<span className={cx("sans sans--caps-11 medium", styles.weekday)}>
							{weekday}
						</span>
						<span
							className={cx("sans sans--caps-11 medium", styles.monthDayMobile)}
						>
							{month} {day}
						</span>
						<span className={cx("serif serif--60 lower", styles.monthDay)}>
							{month} {day}
						</span>
					</div>
					<div className={styles.RSVP}>
						<a
							className={cx("serif serif--60 lower", styles.RSVPLink)}
							href={event.url}
							target="_blank"
							rel="noopener noreferrer"
						>
							RSVP
						</a>
					</div>
					<div className={styles.eventCopy}>
						<h2 className={cx("sans sans--caps-24", styles.eventTitle)}>
							<span className={cx("medium")}>{event.name.text}</span>
							<span className={cx("light")}>{event.summary}</span>
						</h2>
						<span className={cx("sans sans--caps-11 medium", styles.venue)}>
							{event.venue.name}
						</span>

						<AnimatePresence>
							{eventStructuredContent && (
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									className={styles.eventBody}
								>
									{eventStructuredContent.map((module: any, index: number) => {
										return (
											<div
												key={index}
												className={cx(styles.eventBodyModule)}
												dangerouslySetInnerHTML={{
													__html: module.data.body.text,
												}}
											/>
										);
									})}
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</div>
				<div className={styles.RSVPMobile}>
					<a
						className={cx("serif serif--60 lower", styles.RSVPLinkMobile)}
						href={event.url}
						target="_blank"
						rel="noopener noreferrer"
					>
						RSVP
					</a>
				</div>
			</div>
		</ScrollElement>
	);
};
