import React from "react";
import { Router } from "@reach/router";
import { RevealOnMount } from "src/components/reveal-on-mount";

import { Events } from "src/components/events";

const EventsPage = ({
	location,
	pageContext,
}: {
	location: any;
	pageContext: {};
}) => {
	return (
		<RevealOnMount location={location}>
			<Events pageContext={pageContext} />
		</RevealOnMount>
	);
};

export default EventsPage;
