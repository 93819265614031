import React from "react";
import cx from "classnames";

import styles from "./loading-message.module.css";

export const LoadingMessage = ({ message }: { message: string }) => {
	return (
		<div className={styles.loadingMessage}>
			<span className={cx("sans sans--caps-11 medium", styles.message)}>
				{message}
			</span>
		</div>
	);
};
