import React from "react";
import cx from "classnames";
import { ReactComponent as ArrowLeft } from "src/images/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "src/images/svg/arrow-right.svg";

interface ButtonProps {
	className?: string;
	enabled: boolean;
	onClick: () => void;
}
export const PrevButton = ({ className, enabled, onClick }: ButtonProps) => (
	<button
		className={cx("embla__button embla__button--prev", className)}
		onClick={onClick}
		disabled={!enabled}
	>
		<ArrowLeft className="embla__button__svg" />
	</button>
);

export const NextButton = ({ className, enabled, onClick }: ButtonProps) => (
	<button
		className={cx("embla__button embla__button--next", className)}
		onClick={onClick}
		disabled={!enabled}
	>
		<ArrowRight className="embla__button__svg" />
	</button>
);
