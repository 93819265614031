import React, { useEffect } from "react";
import cx from "classnames";
import { getDateTimeParts } from "src/utils/getDateTimeParts";

import { Link as ScrollLink, Element as ScrollElement } from "react-scroll";
import { Event } from "../event";

import styles from "./month.module.css";

export interface MonthProps {
	events: any;
}

export const Month = ({ events }: MonthProps) => {
	return (
		<div className={styles.contentContainer}>
			<div className={styles.contentNav}>
				<ul className={styles.contentNavList}>
					{events &&
						events.map((event: any) => {
							const { month, day } = getDateTimeParts(event.start.local);

							return (
								<li key={event.id}>
									<ScrollLink
										className={styles.contentNavLink}
										activeClass={styles.active}
										to={event.id}
										spy={true}
										smooth={true}
										offset={-33}
										duration={500}
									>
										<span
											className={cx(
												"sans sans--caps-11 medium",
												styles.contentNavLinkTitle
											)}
										>
											{month} {day}
										</span>
									</ScrollLink>
								</li>
							);
						})}
				</ul>
			</div>
			<div className={styles.content}>
				{events &&
					events.map((event: any) => <Event event={event} key={event.id} />)}
			</div>
		</div>
	);
};
